
// $color: #926def, #8c6ced, #876beb, #6565dd, #5f64db;
$color: #9933ff, #f700be, #ff007f, #ff5b4f, #ff9133; 

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
}

$circle-size: 20px;
$circle-margin: 7px; 

.circle {
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  margin: $circle-margin;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    opacity: 0.7;
    animation: scale 2s infinite 
      cubic-bezier(0, 0, 0.49, 1.02);
  }
}

@for $i from 1 through 5 {
  .circle-#{$i} {
    background-color: nth($color, $i);
    &:before {
      background-color: nth($color, $i);
      animation-delay: 200ms * $i;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%, 75% {
    transform: scale(2);
  }
  78%, 100% {
    opacity: 0;
  }
}