/**
 * Formatting for a full screen div with a linear gradient background. This div is the background gradient on every page.
 *
 * @author Eric Doppelt
 */

/* Via https://stackoverflow.com/questions/1719452/how-to-make-a-div-always-full-screen */


.app {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: linear-gradient(#74b1ff, rgba(146, 219, 203, 0.87), #dddddd);
}