/**
 * Formatting for a full screen div used throughout the UI. This div is the background gradient on every page.
 * This is the ONLY use of .css in the entire project, as the rest is styled using Material UI's withStyles functionality.
 * withStyles() is not used here, as it cannot be exported as easily.
 *
 * @author Eric Doppelt
 */

/* Via https://stackoverflow.com/questions/1719452/how-to-make-a-div-always-full-screen */
.fullDiv {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: linear-gradient(#74b1ff, rgba(146, 219, 203, 0.87), #dddddd);
}
